import axios from "@/services/AxiosConfig";
import store from "@/store";
import { getGlobalToast } from "@/toast";

const API_URL = "/configuration-setup";

export const TYPE = {
  POSITION: "POSITION",
  SERVICE_TYPE: "SERVICE_TYPE",
  TERM_CONDITION: "TERM_CONDITION",
  MODEL: "MODEL",
  COLOR: "COLOR",
  SOURCE: "SOURCE",
  BRAND: "BRAND",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  ADDRESS: 'ADDRESS'
};

export class ConfigurationService {
  constructor() {
    this.size = 10;
    this.page = 0;

    this.toast = getGlobalToast();
  }

  async getConfigurationList(params) {
    const {
      type,
      deleteStatus = false,
      status,
      size = 10,
      page = 0,
    } = { ...params };

    try {
      const response = await axios.get(`${API_URL}/${type}`, {
        params: { page, size, ...params, deleteStatus, status },
      });
      return response?.data; // Make sure to return response.data
    } catch (error) {
      // handleError(error);
      // throw error; // Optionally rethrow the error if needed
      return [];
    }
  }

  async getDropdownConfigurations(params) {
    const {
      type,
      deleteStatus = false,
      status,
      size = 10,
      page = 0,
      dropdown = "dropdown",
    } = { ...params };

    try {
      const response = await axios.get(`${dropdown}${API_URL}/${type}`, {
        params: { page, size, ...params, deleteStatus, status },
      });
      return response.data; // Make sure to return response.data
    } catch (error) {
      // handleError(error);
      // throw error; // Optionally rethrow the error if needed
      return [];
    }
  }

  async createConfiguration({
    code = "",
    name,
    description,
    lookup = 0,
    type,
    status,
  }) {
    const body = {
      code,
      lookup,
      remark: description,
      name,
      lookup,
      status: status ? "ACTIVE" : "INACTIVE",
      type,
    };

    return axios
      .post(`${API_URL}/${type || TYPE.SOURCE}/create`, body)
      .then((d) => {
        return d.data;
      })
      .catch((er) => {
        const { status, message } = er?.response?.data || {};
        return [];

      });
  }

  async updateConfiguration({
    code = "",
    name,
    description,
    lookup = 0,
    status,
    id,
    type,
  }) {
    const body = {
      code,
      lookup,
      remark: description,
      name,
      type,
      lookup,
      status: status ? "ACTIVE" : "INACTIVE",
    };
    try {
      return axios
        .put(`${API_URL}/${type}/${id}`, body)
        .then((response) => response)
        .catch((er) => {
          const { status, message } = er?.response?.data || {};
          if (status == 400) this.toast.warning(message);
          // else this.toast.error(message);
          // throw er;
          return [];
        });
    } catch (error) {
      if (error.response) {
        // If the error has a response, return it to be handled by the caller
        return error.response;
      } else {
        // If there's no response, log the error and return a generic message

        return {
          status: 500,
          message: "An unexpected error occurred.",
        };
      }
    }
  }

  async getConfigurationById(ConfigurationId, type) {
    try {
      const response = await axios.get(`${API_URL}/${type}/${ConfigurationId}`);
      const data = {
        ...(response.data || {}),
        status: response.data.status == TYPE.ACTIVE ? true : false,
      };

      return new ConfigurationSetupModel(data);
    } catch (error) {
      // handleError(error);
      throw error;
    }
  }

  async deleteConfiguration({ id }) {
    try {
      const response = await axios.delete(`${API_URL}/${TYPE.SOURCE}/${id}`);
      return response;
    } catch (error) {
      if (error.response) {
        // If the error has a response, return it to be handled by the caller
        return error.response;
      } else {
        // If there's no response, log the error and return a generic message
        console.error(error);
        return {
          status: 500,
          message: "An unexpected error occurred.",
        };
      }
    }
  }


  SOURCE = [];
  MODEL = [];
  SERVICE_TYPE = [];
  COLOR = [];
  BRAND = [];
  CITY = [];

  findAllDropdownLocal() {
    const findConfirgur = store.getters["configurations/allDropdown"];
    const { sources, models, serviceTypes, colors, brands, city } =
      findConfirgur;
    this.SOURCE = sources;
    this.MODEL = models;
    this.SERVICE_TYPE = serviceTypes;
    this.BRAND = brands;
    this.COLOR = colors;
    this.CITY = city;

    return this;
  }
}
const configurationService = new ConfigurationService();

export { configurationService };
export class ConfigurationSetupModel {
  constructor({
    id,
    name,
    type,
    status,
    createdAt,
    updatedAt,
    remark,
    code,
    lookup,
    parent,
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.description = remark;
    this.status = status;
    this.code = code;
    this.lookup = parent?.name;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.parent = parent;
  }
}
