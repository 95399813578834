import leadsStore from "@/modules/leads/leads.store";
import axios from "@/services/AxiosConfig";
import { CredentialService } from "@/services/CredentialService"; // Adjust path as necessary
import { UserPermissionService } from "@/services/UserPermission.service";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import configurations from "./configurationSetup.store";
import menuStore from "./menu.store";

import jobCardStore from "@/modules/jobcard/jobcard.store";
import shareStore from "@/share/share.store";
import userPermission from "./userPermission.store";
import userStore from "./userStore";
Vue.use(Vuex);

const state = {
  user: CredentialService.getCredential(), // Initialize user state from CredentialService
};

const getters = {
  isLoggedIn: (state) => !!state.user.accessToken,
  getUsername: (state) => state.user.username,
  getUserType: (state) => state.user.userType,
  getFullName: (state) => state.user.fullName,
  getUserPermission: (state) => state.user.permission,
};

const mutations = {
  setUser(state, { username, userType, accessToken, fullName, permission }) {
    state.user.fullName = fullName;
    state.user.username = username;
    state.user.userType = userType;
    state.user.accessToken = accessToken;
    state.user.permission = permission;
    CredentialService.setCredential({
      username,
      userType,
      accessToken,
      fullName,
      permission,
    }); // Update localStorage
  },
  SET_PERMISSION(state, data) {
    state.user.permission = data;
    CredentialService.setCredential({
      ...state.user,
      permission: data,
    }); // Update localStorage
  },
  clearUser(state) {
    state.user.fullName = "";
    state.user.username = "";
    state.user.userType = null;
    state.user.accessToken = "";
    state.user.permission = [];
    CredentialService.clearCredential(); // Clear localStorage
  },
};

const actions = {
  async login({ commit }, { username, password }) {
    try {
      const response = await axios.post("auth/login", { username, password });
      const { accessToken, userType, fullName } = response.data;
      const permission =
        await UserPermissionService.apiGetUserPermission(accessToken);

      commit("setUser", {
        username,
        userType,
        accessToken,
        fullName,
        permission,
      });
      return true; // Login successful
    } catch (error) {
      console.error("Login failed:", error);
      return false; // Login failed
    }
  },

  logout({ commit }) {
    commit("clearUser");
  },

  actionPermission({ commit }, data) {
    commit("SET_PERMISSION", data);
  },

  checkLogin({ getters }) {
    return getters.isLoggedIn;
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    userStore,
    configurations,
    userPermission,
    shareStore,
    menuStore,
    leadsStore,
    jobCardStore
  },

  plugins: [
    createPersistedState({ storage: window.localStorage }), // Ensure it's pointing to localStorage})
  ],
});
