import { JobCardsService } from "./jobcard.service";
const ACTON_NAME = {
    SET_LIST: "SET_LIST",
    SET_DROPDOWN_LIST: "SET_DROPDOWN_LIST",
    SET_DETAIL: "SET_DETAIL",
    RESET: "RESET",
    SET_REMARK: "SET_REMARK",
};

const clearState = () => ({
    drowpdownList: [],
    list: {},
    optionFilter: { pageNo: 0, pageSize: 25 },
    loading: false,
    detail: {},
    remarks: [],
});

const state = clearState();

const mutations = {
    [ACTON_NAME.SET_LIST](state, data) {
        state.list = data;
    },

    [ACTON_NAME.SET_DROPDOWN_LIST](state, data) {
        state.drowpdownList = data;
    },

    [ACTON_NAME.SET_DETAIL](state, data) {
        state.detail = data;
    },

    [ACTON_NAME.RESET](state) {
        state = clearState();
    },
    [ACTON_NAME.SET_LIST](state, data) {
        state.list = data;
    },

    [ACTON_NAME.SET_REMARK](state, data) {
        state.remarks = data;
    },
};

const actions = {
    async drowpdownListAction({ commit }) {
        const list = await JobCardsService.getDropdownLeads();
        commit(ACTON_NAME.SET_DROPDOWN_LIST, list);
    },

    async getDetailAction({ commit, state }, { id }) {
        const data = await JobCardsService.getLeadsById({ id });
        console.log([data]);

        commit(ACTON_NAME.SET_DETAIL, data);
        return data;
    },

    async resetState({ commit }) {
        commit(ACTON_NAME.RESET);
    },

    async jobCardsActionList({ commit, state }, { params }) {
        const {
            brandId,
            sourceId,
            phoneNumber,
            plateNumber,
            status: statusCode,
        } = params;
        state.optionFilter = {
            ...state.optionFilter,
            ...params,

            statusCode,
            brandId,
            sourceId,
            phoneNumber,
            plateNumber,
        };
        state.loading = true;
        const jobCards = await JobCardsService.listJobCard({
            params: state.optionFilter,
        });
        console.log([jobCards]);
        commit(ACTON_NAME.SET_LIST, jobCards);
        state.loading = false;
    },

    async actionRemark({ commit, state }, { id }) {
        const list = await JobCardsService.getRemark(id);
        commit(ACTON_NAME.SET_REMARK, list);
        return list?.success;
    },
};

const getters = {
    getDropdownLeads: (state) => state?.drowpdownList?.success || [],
    getDetailjobCards: (state) => state?.detail?.success || [],
    getListjobCards: (state) => {
        return state.list.success || {};
    },
    getLoading: (state) => {
        return state.loading;
    },
    getRemark: (state) => state.remarks.success,
};

export default {
    namespaced: true, // Enables namespacing
    state,
    mutations,
    actions,
    getters,
};
