import api from "@/services/AxiosConfig";

export class MJobCards {
  constructor({ leadId, plateNumber = "", mileage = "", credit = "" }) {
    this.leadId = leadId;
    this.plateNumber = plateNumber;
    this.mileage = mileage;
    this.credit = credit;
  }
}

class JobCardModel {
  constructor(data) {
    this.id = data.id || null;
    this.leadId = data.leadId || null;
    this.jobCardDate = data.jobCardDate || null;
    this.vinNumber = data.vinNumber || '';
    this.plateNumber = data.plateNumber || '';
    this.mileage = data.mileage || '';
    this.warranty = data.warranty || '';
    this.credit = data.credit || '';
    this.remark = data.remark || '';
    this.statusCode = data.statusCode || null;
    this.statusName = data.statusName || '';
    this.customerName = data.customerName || '';
    this.phoneNumber = data.phoneNumber || '';
    this.serviceTypes = data.serviceTypes || [];
    this.serviceAdvisors = data.serviceAdvisors || [];
    this.leadsComplains = data.leadsComplains || [];
    this.modelName = data.modelName || '';
    this.brandId = data.brandId || null;
    this.brandName = data.brandName || '';
    this.modelId = data.modelId || null;
    this.createdBy = data.createdBy || '';
    this.createdAt = data.createdAt || '';
    this.lastModifiedBy = data.lastModifiedBy || '';
    this.lastModifiedAt = data.lastModifiedAt || '';
    this.jobCardId = data.jobCardId || '';
  }

  // Example method to return a formatted string
  getItem(data) {
    return { ...new JobCardModel(data) };
  }
}

const _URL_API = "job-cards";
export class JobCardsService {
  static convertLeadsToJobCard(payload) {
    const parmsBody = { ...payload };
    return api
      .post(`${_URL_API}`, parmsBody)
      .then((res) => ({ success: res.data }))
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }

  static listJobCard({ queryParam }) {
    console.log("lfkdjslfkjsdklfj.....................................");

    const _queryParam = { ...queryParam };
    return api
      .get(`${_URL_API}`, _queryParam)
      .then((res) => ({ success: res.data }))
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }

}
